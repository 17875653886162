<template>
  <div>
    <div v-if="showPage">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import './constant'
import { mapGetters } from 'vuex'
// import { getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
export default {
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      const auth_type = global.APP_CONFIG.auth_type
      const flag =
        auth_type === 'customAuthLogin' ||
        ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') &&
          this.userInfo)
      return flag
    }
  },
  watch: {
    async $route(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      // await getUser()
      await wxSDK.config(
        [],
        ['getContext', 'getCurExternalContact', 'sendChatMessage']
      )
      this.inited = true
    }
  }
}
</script>
